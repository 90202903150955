<template>
  <v-dialog v-model="showTenantUserDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <TenantUserForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          :available-tenants="availableTenants"
          :group-select="groupSelect"
          ref="tenantUserDialogForm"/>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showTenantUserDialog = false">{{ $t('Close') }}</v-btn>
        <v-spacer/>
        <v-btn class="mainAccent lighten-3" dark @click="resetForm">{{ $t('Reset') }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t('Submit') }}</v-btn>
      </v-card-actions>
    </v-card>
    <Loading :visible="isLoading"/>
  </v-dialog>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import TenantUserForm from './Form';
import NotificationMixin from '../../mixins/NotificationMixin';
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";

const servicePrefix = 'TenantUser';

const {mapFields} = createHelpers({
  getterType: 'tenantUser/getField',
  mutationType: 'tenantUser/updateField'
});

export default {
  name: "TenantUserDialog",
  servicePrefix,
  mixins: [NotificationMixin],
  components: {
    Loading,
    TenantUserForm
  },
  props: {
    value: Boolean,
    title: {
      type: String,
      required: true
    },
    handleCreated: {
      type: Function,
      required: false
    },
    handleUpdated: {
      type: Function,
      required: false
    },
    tenantUserItem: {
      type: Object,
      required: false,
    },
    availableTenants: {
      type: Array,
      required: true
    },
    groupSelect: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      item: {},
      createMessage: this.$t("successfullyCreated"),
      updateMessage: this.$t("successfullyUpdated"),
    }
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations', 'updated', 'byId']),
    ...mapGetters('tenantUser', ['find']),
    showTenantUserDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  methods: {
    ...mapActions('tenantUser', {
      create: 'create',
      update: 'update',
      resetData: 'resetData',
      retrieve: 'load'
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.$refs.tenantUserDialogForm.$v.item.$model;
        if (this.handleUpdated && model['@id']) {
          this.update(model);
        }
        if (this.handleCreated && !model['@id']) {
          this.create(model);
        }
      }
    },
    reset() {
      this.$refs.tenantUserDialogForm.$v.$reset();
    },
    resetForm() {
      this.item = {...this.tenantUserItem};
      if (this.$refs['tenantUserDialogForm']) {
        this.$refs.tenantUserDialogForm.$v.$reset();
      }
    },
    isValidForm() {
      this.$refs.tenantUserDialogForm.$v.$touch();
      return !this.$refs.tenantUserDialogForm.$v.$invalid;
    },
  },
  watch: {
    tenantUserItem() {
      this.item = {...this.tenantUserItem};
      this.resetForm();
    },
    created(created) {
      if (!created) {
        return;
      }
      this.showMessage(this.createMessage);
      if (this.handleCreated) {
        this.handleCreated(this.created);
        this.showTenantUserDialog = false;
      }
    },
    updated() {
      this.showMessage(this.updateMessage);
      if (this.handleUpdated) {
        this.handleUpdated(this.updated);
        this.showTenantUserDialog = false
      }
    },
    error(message) {
      message && this.showError(message);
    },
  },
  created() {
    this.item = {...this.tenantUserItem};
  }
}
</script>
