<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-select
            :error-messages="tenantErrors"
            :label="$t('TenantUserTenant')"
            @blur="$v.item.tenant.$touch()"
            @input="$v.item.tenant.$touch()"
            required
            :items="availableTenants"
            v-model="item.tenant"
            item-text="name"
            item-value="@id"
          ></v-select>
        </v-col>
        <v-col cols="12" v-if="groupSelect">
          <v-select
            :error-messages="groupsErrors"
            :label="$t('TenantUserGroup')"
            @blur="$v.item.groups.$touch()"
            @input="$v.item.groups.$touch()"
            required
            multiple
            chips
            :items="groupsSelectItems"
            v-model="item.groups"
            item-text="name"
            item-value="@id"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {mapFields} from "vuex-map-fields";

export default {
  name: 'TenantUserForm',
  mixins: [validationMixin],
  components: {},
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    },
    availableTenants: {
      type: Array,
      required: true
    },
    groupSelect: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      tenant: null,
      groups: null,
      speaker: null,
    };
  },
  computed: {
    ...mapFields('tenantGroup', {
      groupsSelectItems: 'selectItems',
      groupsById: 'byId'
    }),
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    validateGroups() {
      return !!(this.availableGroups);
    },
    tenantErrors() {
      const errors = [];
      if (!this.$v.item.tenant.$dirty) {
        return errors;
      }

      has(this.violations, 'tenant') && errors.push(this.violations.tenant);

      !this.$v.item.tenant.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    groupsErrors() {
      const errors = [];
      if (!this.$v.item.groups.$dirty) {
        return errors;
      }

      has(this.violations, 'groups') && errors.push(this.violations.groups);

      !this.$v.item.groups.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    violations() {
      return this.errors || {};
    }
  },
  methods: {
    log(message) {
      console.log(message);
    }
  },
  validations: {
    item: {
      tenant: {
        required,
      },
      backendUser: {},
      speaker: {},
      groups: {}
    }
  },
}
</script>
