import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import AuthenticationMixin from "@/mixins/AuthenticationMixin";

export default {
  mixins: [AuthenticationMixin],
  data() {
    return {
      tenantSpeakerFilter: {
        options: {
          sortBy: [],
          descending: false,
          page: 1,
          itemsPerPage: 200
        },
        filters: {
          exists: {
            'speaker': true
          }
        },
      },
      loadingTenantUsers: [],
      loadingSpeakers: [],
    };
  },
  watch: {},
  computed: {
    ...mapFields("tenantUser", {
      tenantUserIds: "allIds",
      tenantUserIdsCached: "cacheAllIds",
      deletedTenantUserItem: 'deleted',
    }),
    ...mapGetters("tenantUser", {
      findTenantUser: "find",
    }),

    ...mapFields('tenant', {
      tenantsById: 'byId',
    }),
    ...mapFields('speaker', {
      speakersById: 'byId',
      speakerUserIds: 'allIds',
      speakerUserIdsCached: "cacheAllIds",
    }),
    ...mapGetters('speaker', {
      speakerItems: 'list',
      findSpeaker: 'find'
    }),
  },
  methods: {
    ...mapActions("tenantUser", {
      retrieveTenantUser: "load",
      deleteTenantUserItem: 'del',
      updateTenantUser: 'update',
    }),
    ...mapActions('tenant', {
      tenantsGetSelectItems: 'fetchSelectItems',
    }),
    ...mapActions('speaker', {
      retrieveSpeaker: 'load',
    }),
    resolveTenantUser(itemId) {
      if (!this.isTenantUserLoaded(itemId) && !this.loadingTenantUsers.includes(itemId)) {
        this.loadingTenantUsers.push(itemId);
        this.retrieveTenantUser(decodeURIComponent(itemId));
      }
      return this.findTenantUser(itemId);
    },
    resolveTenantUsers(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveTenantUser(itemId);
      }).filter(e => e);
    },
    isTenantUserLoaded(itemId) {
      return this.tenantUserIdsCached.includes(itemId);
    },
  }
};
